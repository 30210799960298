import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';

import { axiosQuery } from './axiosQuery';

interface Customer {
  reference_id: string; // Use Oura account id for this
  id: string;
  email: string;
  created_at: number; // timestamp
}

type HSACardType = 'hsa' | 'fsa' | 'visa' | 'amex' | 'mastercard' | 'discover' | 'other';

interface Card {
  id: string;
  brand: HSACardType;
  last_four: string; // "1111"
  country: string | undefined; // was undefined on Sandbox, even though given in dialog
  description: string | undefined; // was undefined on SBX
  expiration_month: string; // "10"
  expiration_year: string; // "2025"
  created_at: number;
}

const SikaButtonType = 'cardFormButton';

export interface RenderElementInputs {
  merchantId: string;
  customer: Partial<Customer>;
  onCancel: () => void;
  onError: (error: unknown) => void;
  onSave: (input: { customer: Customer; card: Card }) => void;
}

declare global {
  interface Window {
    sika: {
      renderElement: (form: typeof SikaButtonType, id: string, input: RenderElementInputs) => void;
    };
  }
}

export const initHSA = (args: {
  accountId: string;
  email: string;
  componentId: string;
  merchantId: string;
  onCancel: RenderElementInputs['onCancel'];
  onError: RenderElementInputs['onError'];
  onSuccess: RenderElementInputs['onSave'];
}) => {
  const { accountId, email, componentId, merchantId, onCancel, onError, onSuccess } = args;

  window.sika.renderElement(SikaButtonType, componentId, {
    merchantId,
    customer: { reference_id: accountId, email },
    onCancel,
    onError,
    onSave: onSuccess,
  });
};

export interface CreateSikaPaymentMethodInput {
  token: string;
  cardLastFour: string; // "1111"
  cardType: HSACardType;
  customerId: string;
  expirationMonth: string; // or number
  expirationYear: string; // or number
}

export interface CreateSikaPaymentMethodResponse {
  paymentMethodId: string;
}

const SIKA_PAYMENT_METHOD_MUTATION = gql`
  mutation SikaPaymentMethod(
    $token: String!
    $cardLastFour: String!
    $cardType: String!
    $customerId: String!
    $expirationMonth: Int!
    $expirationYear: Int!
  ) {
    createSikaHealthPaymentMethod(
      token: $token
      cardLastFour: $cardLastFour
      cardType: $cardType
      customerId: $customerId
      expirationMonth: $expirationMonth
      expirationYear: $expirationYear
    ) {
      paymentMethodId
    }
  }
`;

export const useCreateSikaPaymentMethod = () =>
  useMutation<CreateSikaPaymentMethodResponse, Error, CreateSikaPaymentMethodInput>({
    mutationFn: async (inputs) => {
      const variables = {
        ...inputs,
        // Convert String to Int
        expirationMonth: Number.parseInt(inputs.expirationMonth, 10),
        expirationYear: Number.parseInt(inputs.expirationYear, 10),
      };

      const response = await axiosQuery<{
        createSikaHealthPaymentMethod: CreateSikaPaymentMethodResponse;
      }>('SikaPaymentMethod', SIKA_PAYMENT_METHOD_MUTATION, variables);
      return response.createSikaHealthPaymentMethod;
    },
  });
