import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { styled } from 'styled-components';
import { t } from 'translations';

import { Button, CTALoader, Form, Loader, RadioGroup, RadioGroupOption, SubmitButton } from 'ui';
import { SmallText } from 'ui/styles/text';
import { media } from 'ui/themes/signup';

import { useCreatePaymentSignature } from '../../hooks/useCreatePaymentSignature';
import { FlowType, HostedPageParams, PrepopulateFields } from '../../types';
import { throwError } from '../../utils/errorHandler';
import { ClientErrorMessageCallback, addEventHandler, submitForm } from '../../utils/zuora';
import { PaymentDisclaimer } from '../PaymentDisclaimer';

import CreditCardLogo from './assets/credit.svg?react';
import HSALogo from './assets/hsa.svg?react';
import PaypalLogo from './assets/paypal.svg?react';
import { CreditCardForm } from './CreditCardForm';
import { HSAForm, HSAFormProps } from './HSAForm';
import { PayPalForm, PayPalFormProps } from './PayPalForm';

const PAGE_URL: HostedPageParams['url'] = import.meta.env.VITE_ZUORA_PAGE_URL;

type PaymentMethod = 'card' | 'paypal' | 'hsa';

const PaymentForm = styled(Form)`
  align-items: center;
  gap: 25px;

  & > div {
    width: 100%;
  }

  /* Overrides default form behaviour */
  ${Button}[type="submit"] {
    align-self: center;
    margin: 0;
  }

  ${media.xlarge`
    align-items: flex-start;
  `}
`;

const MethodContainer = styled.div<{ $active: boolean }>(
  ({ $active }) => `
  display: ${$active ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`,
);

const LabelText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface PaymentMethodFormProps {
  creditCard: {
    pageId: string;
    gatewayName: string;
    prepopulatedFields: PrepopulateFields;
    onSubmit: React.MouseEventHandler<HTMLButtonElement>;
    clientErrorCallback: ClientErrorMessageCallback;
  };
  paypal: {
    gatewayName: string;
    componentId: PayPalFormProps['id'];
    merchantId: string;
    onSubmit: PayPalFormProps['onStart'];
  };
  hsa: {
    enabled: boolean;
    accountId: string;
    email: string;
    componentId: HSAFormProps['id'];
    merchantId: string;
    onCancel: HSAFormProps['onCancel'];
    onError: HSAFormProps['onError'];
    onSubmit: HSAFormProps['onSubmit'];
    onSuccess: HSAFormProps['onSuccess'];
  };
  flowType: FlowType;
}

export const PaymentMethodForm = ({ flowType, creditCard, paypal, hsa }: PaymentMethodFormProps) => {
  const intl = useIntl();

  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [isZuoraLoaded, setIsZuoraLoaded] = useState<boolean>(false);

  const { register, watch } = useForm<{
    paymentMethod: PaymentMethod;
  }>({
    defaultValues: {
      paymentMethod: undefined,
    },
  });
  const paymentMethod = watch('paymentMethod');

  // Use Zuora event handler for any side-effects
  const onZuoraSubmit = () => {
    setDisableSubmit(true);
  };
  addEventHandler('onSubmit', onZuoraSubmit);

  const onZuoraLoad = () => {
    setDisableSubmit(false);
    setIsZuoraLoaded(true);
  };
  addEventHandler('onloadCallback', onZuoraLoad);

  const onZuoraUnload = () => {
    setDisableSubmit(true);
    setIsZuoraLoaded(false);
  };

  const { data: paymentSignature, isPending, error, mutate: getSignature } = useCreatePaymentSignature();

  const pageId = creditCard.pageId; // can be an empty string until gateways are fetched
  // On payment method selection change
  useEffect(() => {
    if (paymentMethod !== 'card') {
      onZuoraUnload();
    }

    if (paymentMethod === 'card' && pageId) {
      getSignature({ pageId });
    }
  }, [pageId, getSignature, paymentMethod]);

  if (error) {
    throwError('failedPaymentSignatureCreation', error);
  }

  const hostedPageParams: HostedPageParams | undefined = paymentSignature && {
    ...paymentSignature,
    id: creditCard.pageId,
    paymentGateway: creditCard.gatewayName,
    style: 'inline',
    submitEnabled: false,
    locale: intl.locale,
    url: PAGE_URL,
    field_passthrough1: flowType,
  };

  const isCCFormReadyForRendering = !isPending && hostedPageParams;
  const showLoader = !isZuoraLoaded;

  // TODO: we need to re-initialize this container always fetch up-to-date signatures
  // This is done in useEffect, so render null to re-mount on selection
  // Refactor incoming https://jouzen.atlassian.net/browse/MMBER-6476
  const creditCardForm = paymentMethod === 'card' && (
    <MethodContainer $active={true}>
      {showLoader && <Loader isWholePage={false} />}
      {isCCFormReadyForRendering && (
        <>
          <CreditCardForm
            hostedPageParams={hostedPageParams}
            prepopulatedFields={creditCard.prepopulatedFields}
            clientErrorCallback={creditCard.clientErrorCallback}
          />
          <SubmitButton
            disabled={!isZuoraLoaded || disableSubmit}
            onClick={(e) => {
              e.preventDefault();
              creditCard.onSubmit(e);
              submitForm();
            }}
            data-testid="credit-card-submit-button"
          >
            {disableSubmit ? <CTALoader /> : t('membership_signup_payment_method_button_submit')}
          </SubmitButton>
        </>
      )}
    </MethodContainer>
  );

  const paypalForm = (
    <MethodContainer $active={paymentMethod === 'paypal'}>
      <PayPalForm id={paypal.componentId} onStart={paypal.onSubmit} paypalMerchantId={paypal.merchantId} />
    </MethodContainer>
  );

  const hsaForm = (
    <MethodContainer $active={paymentMethod === 'hsa'}>
      <HSAForm
        accountId={hsa.accountId}
        email={hsa.email}
        id={hsa.componentId}
        merchantId={hsa.merchantId}
        onCancel={hsa.onCancel}
        onError={hsa.onError}
        onSubmit={hsa.onSubmit}
        onSuccess={hsa.onSuccess}
      />
    </MethodContainer>
  );

  return (
    <PaymentForm
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <RadioGroup>
        <RadioGroupOption
          {...register('paymentMethod')}
          data-testid="credit-card-selector-button"
          value="card"
          labelText={
            <LabelText>
              <CreditCardLogo height="24px" />
              <SmallText $fontWeight="bold" $color="white">
                {t('membership_hub_credit_card')}
              </SmallText>
            </LabelText>
          }
        >
          {creditCardForm}
        </RadioGroupOption>

        <RadioGroupOption
          {...register('paymentMethod')}
          data-testid="paypal-selector-button"
          value="paypal"
          labelText={
            <LabelText>
              <PaypalLogo height="24px" />
              <SmallText $fontWeight="bold" $color="white">
                {t('membership_hub_paypal')}
              </SmallText>
            </LabelText>
          }
        >
          {paypalForm}
        </RadioGroupOption>

        {hsa.enabled && (
          <RadioGroupOption
            {...register('paymentMethod')}
            value="hsa"
            labelText={
              <LabelText>
                <HSALogo height="24px" />
                <SmallText $fontWeight="bold" $color="white">
                  HSA/FSA
                </SmallText>
              </LabelText>
            }
          >
            {hsaForm}
          </RadioGroupOption>
        )}
      </RadioGroup>
      <PaymentDisclaimer />
    </PaymentForm>
  );
};
