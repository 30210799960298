import { useQuery } from '@tanstack/react-query';

import { getPaymentGateways } from '../graphql/getPaymentGateways';
import { Currency, PaymentGateways } from '../types';

const APP_URL: string = import.meta.env.VITE_URL;

export const useGetPaymentGateways = (country: string, currency: Currency) =>
  useQuery<PaymentGateways>({
    queryKey: ['getPaymentGateways', country, currency],
    queryFn: async () => {
      const response = await getPaymentGateways({
        host: new URL(APP_URL).host,
        country,
        currency,
      });
      return response.paymentGateways;
    },
    staleTime: Infinity,
  });
