import axios from 'axios';

import { createSupaGQLError } from './SupaGraphQLError';

import type { GraphQLError } from 'graphql';

interface GraphQlResponse<T = unknown> {
  data: T;
  errors?: GraphQLError[];
}

export const axiosQuery = async <T = unknown>(operationName: string, query: string, variables = {}): Promise<T> => {
  // This needs to be inside this function for tests to pass :/
  const GQL_URL: string = import.meta.env.VITE_GRAPHQL_URL;
  const response = await axios<GraphQlResponse<T>>({
    url: GQL_URL,
    method: 'post',
    data: {
      operationName,
      query,
      variables,
    },
    withCredentials: true,
  });

  const error = response.data.errors?.[0];
  if (error) {
    throw createSupaGQLError(error);
  }

  return response.data.data;
};
