import { useSearchParams } from 'react-router-dom';

import { useCompleteLogin, useInitLogin } from 'apps-common/hooks/AccountApi';
import { identify, setUserId } from 'apps-common/utils/analytics';
import { throwError } from 'apps-common/utils/errorHandler';
import { logger } from 'apps-common/utils/logger';
import { Loader } from 'ui';

interface LoginFormProps {
  onLogin: () => void;
}

const MoiLogin = ({ onLogin }: LoginFormProps) => {
  const [searchParams] = useSearchParams();
  logger.info('Moi login started', { auth: 'moi' });
  const error = searchParams.get('error') ?? undefined;

  if (error) {
    const moiErrorCode = searchParams.get('error_description') ?? '';
    logger.error('Moi login failed', {
      auth: 'moi',
      reason: 'Error in login: ' + error,
    });
    if (moiErrorCode === 'signup_required') {
      throwError('moiNoAccountFound');
    } else throwError('signupUnknown', error);
  }

  const redirectUri = window.location.href.split('?')[0];
  const state = searchParams.get('state') ?? '';
  const code = searchParams.get('code') ?? '';

  const { data: initData, error: errorFetching, isFetching: isFetching } = useInitLogin(redirectUri, !(state && code));

  if (errorFetching) {
    logger.error('Moi login failed', {
      auth: 'moi',
      reason: 'Error in initing login data',
    });
    throwError('signupUnknown', errorFetching);
  }

  const {
    data: endLoginData,
    error: errorFetchingEnd,
    isFetching: isFetchingLoginEnd,
  } = useCompleteLogin(redirectUri, code, state, state !== '' && code !== '');

  if (errorFetchingEnd) {
    logger.error('Moi login failed', {
      auth: 'moi',
      reason: 'Error in completing login',
    });
    throwError('signupUnknown', errorFetchingEnd);
  }

  if (isFetching || isFetchingLoginEnd) {
    return <Loader />;
  }
  if (initData?.authorizationRequestUrl) {
    window.location.href = initData.authorizationRequestUrl;
  }

  if (endLoginData) {
    setUserId(endLoginData.analyticsId);
    identify({ traits: { isHipaa: endLoginData.isHipaa } });
    onLogin();
    logger.info('Moi login completed', { auth: 'moi' });
    window.location.href = '/';
  }

  return null;
};

export default MoiLogin;
