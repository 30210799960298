import { ReactNode } from 'react';

import { FormattedNumber } from 'react-intl';
import { t } from 'translations/src/LocaleContext';

import { GetAccountResponse } from 'apps-common/graphql/getAccount';
import { BillingPeriod, PaymentMethodState, SubscriptionState } from 'apps-common/types';
import { LinkTextColor } from 'ui/components/LinkSection';

import { RowValue } from '../../components/SectionCard/PreviewRow';
import { routes } from '../../routes';
import {
  getPaymentMethodStatus,
  isB2BMember,
  isDunningSubscription,
  isExpiredSubscription,
  isLifetimeMember,
  isPrepaidMember,
  isTrialSubscription,
} from '../../utils/member';

export const localizeDateString = (dateString: string | undefined) => {
  if (!dateString) {
    return '';
  }
  const date = new Date(dateString);

  const localizedDate = date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });

  return localizedDate;
};

const getMembershipPaymentInfo = (account: GetAccountResponse['account']) => {
  if (!account.currentSubscription?.recurringFee) return null;
  const { currentSubscription, dunningLevel } = account;
  const { billingPeriod, amount, currency } = currentSubscription.recurringFee!;
  let paymentInfo: ReactNode = '';

  const price = <FormattedNumber value={amount} style="currency" currency={currency} />;
  paymentInfo = t('membership_hub_membership_plan_next_payment', {
    price: price,
    date: localizeDateString(currentSubscription?.nextPaymentDate),
  });

  const currentPlanType =
    billingPeriod === BillingPeriod.Months
      ? t('membership_hub_membership_plan_type_monthly')
      : t('membership_hub_membership_plan_type_annual');

  const divider = ' | ';

  if (isDunningSubscription(dunningLevel)) {
    paymentInfo = t('membership_hub_update_payment_info');
  }

  return { currentPlanType, paymentInfo, divider };
};

// b2c subscription membership plan which can have prepaid also
const handleMemberStateMembershipPlan = (account: GetAccountResponse['account']) => {
  const currentSubscription = account.currentSubscription;
  const prepaidPeriods = account.currentSubscription?.prepaidPeriods;
  const pendingCancellation = account.currentSubscription?.pendingCancellation;

  if (isPrepaidMember(currentSubscription, 'current')) {
    const currentPrepaidPeriod = prepaidPeriods?.current;

    const rowValue: RowValue[] = [
      {
        value: `${localizeDateString(currentPrepaidPeriod?.start)} - ${localizeDateString(currentPrepaidPeriod?.end)}`,
      },
    ];
    if (pendingCancellation) {
      handlePendingCancellation(currentPrepaidPeriod?.end, rowValue);
    }

    return {
      label: t('membership_hub_prepaid'),
      planDetails: [{ value: rowValue }],
    };
  } else {
    if (pendingCancellation) {
      const rowValue: RowValue[] = [
        {
          value: `${localizeDateString(currentSubscription?.startDate)} - ${localizeDateString(currentSubscription?.endDate)}`,
        },
      ];
      handlePendingCancellation(currentSubscription?.endDate, rowValue);

      return {
        label: t('membership_hub_membership_plan'),
        planDetails: [{ value: rowValue }],
      };
    }

    const membershipInfo = getMembershipPaymentInfo(account);
    if (!membershipInfo) return null;

    const membershipPlan = {
      label: t('membership_hub_membership_plan'),
      planDetails: [
        {
          value: [
            { value: membershipInfo.currentPlanType },
            { value: membershipInfo.divider },
            {
              value: membershipInfo.paymentInfo,
              color: 'lightGreen',
            } as RowValue,
          ],
        },
      ],
    };

    return membershipPlan;
  }
};

export const getUpcomingPlans = (account: GetAccountResponse['account']) => {
  if (!account.currentSubscription || account.currentSubscription.pendingCancellation) {
    return [];
  }

  /*
   - if current plan is a monthly / annual membership then the future plan can be only prepaid or nothing (unless we add a changed plan subscription as an upcoming plan)
   - if current plan is prepaid membership then the future plan can only be monthly / annual
   - if current plan is trial then the future plan can be prepaid, or monthly / annual
   */
  const { currentSubscription } = account;
  const prepaidPeriods = currentSubscription?.prepaidPeriods;
  const prepaidPlan = prepaidPeriods?.future;
  const upcomingPlans = [];

  if (isPrepaidMember(currentSubscription, 'future')) {
    upcomingPlans.push({
      label: t('membership_hub_prepaid'),
      planDetails: [
        {
          value: `${localizeDateString(prepaidPlan?.start)} - ${localizeDateString(prepaidPlan?.end)}`,
        },
      ],
    });
  }

  // only prepaid or trial subscription can have upcoming membership plan. current membership plan can't have upcoming membership plan unless we add a changed plan subscription as an upcoming plan
  if (isPrepaidMember(currentSubscription, 'current') || isTrialSubscription(currentSubscription?.subscriptionState)) {
    const membershipInfo = getMembershipPaymentInfo(account);
    if (membershipInfo) {
      upcomingPlans.push({
        label: t('membership_hub_membership_plan'),
        planDetails: [
          {
            value: [
              { value: membershipInfo.currentPlanType },
              { value: membershipInfo.divider },
              {
                value: membershipInfo.paymentInfo,
                color: 'lightGreen',
              } as RowValue,
            ],
          },
        ],
      });
    }
  }

  return upcomingPlans;
};

const handlePendingCancellation = (date: string | undefined, rowValue: RowValue[]) => {
  const daysLeft = daysUntil(date);
  if (daysLeft && daysLeft < 32) {
    rowValue.push({ value: ' | ' });
    if (daysLeft === 1) {
      rowValue.push({
        value: t('membership_hub_one_day_left'),
        color: 'lightRed',
      });
    } else {
      rowValue.push({
        value: t('membership_hub_days_left', { days: daysLeft }),
        color: 'lightRed',
      });
    }
  }

  return rowValue;
};

export const getCurrentPlan = (
  accountData: GetAccountResponse,
): { label: ReactNode; planDetails?: RowValue[] } | null | undefined => {
  const {
    account: { membershipType, currentSubscription },
    membershipOffering: { prepaidMonths },
  } = accountData;
  if (!currentSubscription) return null;
  const { subscriptionState, pendingCancellation } = currentSubscription;

  // all EXPIRED b2c/b2b user case (not pending cancellation - handled as MEMBER state)
  if (isExpiredSubscription(subscriptionState)) {
    if (prepaidMonths > 0) {
      return {
        label: t('membership_hub_prepaid_pending_label'),
        planDetails: [
          {
            value: t('membership_pending_prepaid_months', {
              months: prepaidMonths,
            }),
          },
          {
            value: t('membership_hub_prepaid_pending_description'),
            color: 'lightRed',
          },
        ],
      };
    }

    return {
      label: t('membership_hub_expired_membership'),
      planDetails: [
        {
          value: t('membership_hub_inactive_description_get_membership_benefits'),
          color: 'lightRed',
        },
      ],
    };
  }

  const isB2B = isB2BMember(membershipType);
  if (isB2B) {
    return {
      label: t('membership_hub_b2b_plan_description'),
      planDetails: [
        {
          value: `${localizeDateString(currentSubscription.startDate)} - ${localizeDateString(currentSubscription.endDate)}`,
        },
      ],
    };
  }

  switch (subscriptionState) {
    case SubscriptionState.LIFETIME:
      return {
        label: t('membership_hub_lifetime_membership'),
      };
    case SubscriptionState.TRIAL: {
      const rowValue: RowValue[] = [
        {
          value: `${localizeDateString(currentSubscription.startDate)} - ${localizeDateString(currentSubscription.endDate)}`,
        },
      ];
      if (pendingCancellation) {
        handlePendingCancellation(currentSubscription?.endDate, rowValue);
      }
      return {
        label: t('membership_hub_x_month_trial'),
        planDetails: [
          {
            value: rowValue,
          },
        ],
      };
    }

    case SubscriptionState.MEMBER:
      return handleMemberStateMembershipPlan(accountData.account);
  }
};

// bannerErrorState color for renew
interface CTARowProps {
  cta: {
    label: string;
    color?: LinkTextColor;
    href?: string;
  };
}
export const getCTAInfo = (account: GetAccountResponse['account']): CTARowProps | null | undefined => {
  const { currentSubscription, dunningLevel, paymentMethods } = account;
  const paymentMethodStatus = getPaymentMethodStatus(paymentMethods[0]);
  const isB2BActiveMember =
    isB2BMember(account.membershipType) && currentSubscription?.subscriptionState === SubscriptionState.MEMBER;
  // ACTIVE b2b or lifetime members don't need CTA to change plan
  if (!currentSubscription || isB2BActiveMember || isLifetimeMember(account.membershipType)) return null;

  const { subscriptionState, recurringFee, pendingCancellation } = currentSubscription;

  if (pendingCancellation || isExpiredSubscription(subscriptionState)) {
    return {
      cta: {
        label: 'membership_hub_renew_membership',
        href: routes.reviewPaymentMethodForRenew,
        color: 'bannerErrorState',
      },
    };
  }

  // update payment method cta
  if ((dunningLevel ?? !currentSubscription.recurringFee) || paymentMethodStatus !== PaymentMethodState.ACTIVE) {
    return {
      cta: {
        label: 'membership_hub_cta_update_payment_method',
        href: routes.SelectAddressUsage,
      },
    };
  }

  switch (subscriptionState) {
    case SubscriptionState.TRIAL:
    case SubscriptionState.MEMBER:
      return {
        cta: {
          label:
            recurringFee?.billingPeriod === BillingPeriod.Years
              ? 'membership_hub_update_membership_plan_link'
              : 'membership_hub_switch_to_annual_plan',
          href: routes.updatePlan,
        },
      };
  }
};

const daysUntil = (date?: string) => {
  if (!date) return null;
  const today = new Date();
  const targetDate = new Date(date);
  today.setHours(0, 0, 0, 0);
  targetDate.setHours(0, 0, 0, 0);

  const diffInMs = targetDate.getTime() - today.getTime();
  const differenceInDays = diffInMs / (1000 * 60 * 60 * 24);

  return Math.floor(differenceInDays);
};
