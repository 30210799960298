import { type DefaultTheme, styled } from 'styled-components';

export interface TextProps {
  readonly $textAlign?: string;
  readonly $fontSize?: keyof DefaultTheme['fontSizes'];
  readonly $color?: keyof DefaultTheme['colors'];
  readonly $padding?: string;
  readonly $fontWeight?: string;
  readonly $margin?: string;
  readonly $lineHeight?: string;
  readonly $maxWidth?: string;
  readonly $position?: string;
}

interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  readonly $decoration?: string;
  readonly $fontSize: string;
  readonly $color?: keyof DefaultTheme['colors'];
  readonly $textAlign?: string;
  readonly $fontWeight?: string;
  readonly $paddingBottom?: string;
}

export const HeaderText = styled.h1<TextProps>(
  ({
    theme,
    $textAlign = 'inherit',
    $fontSize = 'large',
    $color = 'white',
    $fontWeight = '400',
    $margin = '0',
    $lineHeight = '130%',
  }) => `
  text-align: ${$textAlign};
  font-family: ${theme.fonts};
  font-style: normal;
  font-weight: ${$fontWeight};
  font-size: ${theme.fontSizes[$fontSize]};
  line-height: ${$lineHeight};
  color: ${theme.colors[$color]};
  margin: ${$margin};
`,
);
export const MediumTitle = styled.h2<TextProps>(
  ({ theme, $textAlign = 'left', $fontSize = 'xlarge', $color = 'white' }) => `
  text-align: ${$textAlign};
  font-family: ${theme.fonts};
  font-style: normal;
  font-weight: 700;
  font-size: ${theme.fontSizes[$fontSize]};
  line-height: 110%;
  color: ${theme.colors[$color]};
`,
);

export const SectionTitle = styled.h3<TextProps>(
  ({ theme, $textAlign = 'left', $fontSize = 'medium', $color = 'white', $margin = '0', $lineHeight = '125%' }) => `
  text-align: ${$textAlign};
  font-family: ${theme.fonts};
  font-style: normal;
  font-weight: 700;
  font-size: ${theme.fontSizes[$fontSize]};
  line-height: ${$lineHeight};
  color: ${theme.colors[$color]};
  margin: ${$margin};
`,
);
export const CardTitle = styled.h3<TextProps>(
  ({ theme, $textAlign = 'left', $fontSize = 'medium', $color = 'white', $margin = '0', $lineHeight = '125%' }) => `
  text-align: ${$textAlign};
  font-family: ${theme.fonts};
  font-style: normal;
  font-weight: 400;
  font-size: ${theme.fontSizes[$fontSize]};
  line-height: ${$lineHeight};
  color: ${theme.colors[$color]};
  margin: ${$margin};
  position: absolute;
  left: 12px; right: 12px; bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
`,
);

export const Paragraph = styled.p<TextProps>(
  ({
    theme,
    $textAlign = 'inherit',
    $fontSize = 'medium',
    $color = 'white',
    $padding = '0',
    $margin = 'inherit',
    $lineHeight = '150%',
  }) => `
  text-align: ${$textAlign};
  font-family:  ${theme.fonts};
  font-style: normal;
  font-weight: 400;
  font-size: ${theme.fontSizes[$fontSize]};
  line-height: ${$lineHeight};
  color: ${theme.colors[$color]};
  padding: ${$padding};
  margin: ${$margin};
`,
);

export const Text = styled.span<TextProps>(
  ({
    theme,
    $textAlign = 'left',
    $fontSize = 'medium',
    $color = 'white',
    $fontWeight = '400',
    $margin = '0px',
    $lineHeight = '16px',
  }) => `
  text-align: ${$textAlign};
  font-family:  ${theme.fonts};
  font-style: normal;
  font-weight: ${$fontWeight};
  font-size: ${theme.fontSizes[$fontSize]};
  line-height: ${$lineHeight};
  color: ${theme.colors[$color]};
  margin: ${$margin}
`,
);

export const SmallText = styled.p<TextProps>(
  ({
    theme,
    $textAlign = 'left',
    $fontSize = 'small',
    $color = 'grayLightest',
    $fontWeight = '400',
    $margin = '0px',
    $maxWidth = 'none',
  }) => `
  text-align: ${$textAlign};
  font-family:  ${theme.fonts};
  font-style: normal;
  font-weight: ${$fontWeight};
  font-size: ${theme.fontSizes[$fontSize]};
  line-height: 140%;
  color: ${theme.colors[$color]};
  margin: ${$margin};
  max-width: ${$maxWidth};
`,
);

export const FooterParagraph = styled.p<TextProps>(
  ({ theme, $textAlign = 'center', $fontSize = 'xxsmall', $color = 'grayLightest' }) => `
  text-align: ${$textAlign};
  font-family: ${theme.fonts};
  font-size: ${theme.fontSizes[$fontSize]};
  font-weight: 400;
  font-style: normal;
  line-height: 14px;
  gap: 82px;
  align-items: flex-start;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  color: ${theme.colors[$color]};
`,
);

export const Link = styled.a<LinkProps>(
  ({
    theme,
    $decoration = 'underline',
    $fontSize,
    $color = 'grayLightest',
    $textAlign = 'center',
    $fontWeight = '400',
    $paddingBottom = '8px',
  }) => `
  text-align: ${$textAlign};
  font-family: ${theme.fonts};
  font-size: ${$fontSize};
  font-weight: ${$fontWeight};
  font-style: normal;
  line-height: 20px;
  padding-bottom: ${$paddingBottom};
  color: ${theme.colors[$color]};
  text-decoration: ${$decoration};
`,
);

export const StepIndicator = styled.h2<TextProps>(
  ({ theme, $textAlign = 'left', $fontSize = 'medium', $color = 'helsinkiBlue' }) => `
  text-align: ${$textAlign};
  font-family: ${theme.fonts};
  font-style: normal;
  font-weight: 400;
  font-size: ${theme.fontSizes[$fontSize]};
  line-height: 150%;
  color: ${theme.colors[$color]};
`,
);
