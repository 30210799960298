import { ReactNode } from 'react';

import { t } from 'translations';

import { GetAccountResponse } from 'apps-common/graphql/getAccount';
import { BillingPeriod, MembershipType, PaymentMethodState, Subscription, SubscriptionState } from 'apps-common/types';
import { throwError } from 'apps-common/utils/errorHandler';
import { isStrictNever } from 'apps-common/utils/isStrictNever';
import { periodToProductPlanNameKey } from 'apps-common/utils/productLocalization';

import { isB2BMember } from './member';

interface getBillingInformationProps {
  dunningLevel: GetAccountResponse['account']['dunningLevel'];
  paymentMethodStatus: PaymentMethodState;
  nextPaymentDate: Subscription['nextPaymentDate'];
  pendingCancellation: Subscription['pendingCancellation'];
  membershipType: MembershipType;
  subscriptionState: Subscription['subscriptionState'];
  endDate: Subscription['endDate'];
  billingPeriod?: BillingPeriod;
  pendingPrepaidMonths: number;
  isValidCurrency: boolean;
}

interface BillingInformation {
  title: ReactNode;
  content: ReactNode;
  color: 'lightGreen' | 'lightRed';
  show: {
    paymentDetails: boolean;
    paymentCTA: boolean;
    billingHistoryCTA: boolean;
    membershipPlanCTA: boolean;
  };
}

type B2BSubscriptionState = SubscriptionState.EXPIRED | SubscriptionState.MEMBER | SubscriptionState.LIFETIME;

const ACTIVE_COLOR = 'lightGreen';
const INACTIVE_COLOR = 'lightRed';
const LOKALISED_ACTIVE_STATUS = t('membership_hub_billing_status_active');
const LOKALISED_INACTIVE_STATUS = t('membership_hub_billing_status_inactive');
const LOKALISED_PENDING_STATUS = t('membership_hub_billing_status_dormant_subscription');
const LOKALISED_INACTIVE_MEMBERSHIP = t('membership_hub_inactive_description_get_membership_benefits');
const LOKALISED_PREPAID_PENDING_STATUS = t('membership_hub_billing_status_pending_prepaid');
const LOKALISED_PREPAID_PENDING_MEMBERSHIP = t('membership_hub_billing_status_pending_prepaid_membership');
const DROPOUT_OR_CORRUPTED_ACCOUNT = null;

const handleB2BStatus = (subscriptionState: B2BSubscriptionState, isPrepaidPending: boolean): BillingInformation => {
  const LOKALISED_B2B_MEMBERSHIP = t('membership_hub_billing_status_active_description_b2b_membership');
  const LOKALISED_B2B_INACTIVE_MEMBERSHIP = t('membership_hub_billing_status_inactive_description_b2b_membership');

  switch (subscriptionState) {
    case SubscriptionState.EXPIRED: {
      return {
        title: isPrepaidPending ? LOKALISED_PREPAID_PENDING_STATUS : LOKALISED_INACTIVE_STATUS,
        content: isPrepaidPending ? LOKALISED_PREPAID_PENDING_MEMBERSHIP : LOKALISED_B2B_INACTIVE_MEMBERSHIP,
        color: INACTIVE_COLOR,
        show: {
          paymentDetails: false,
          membershipPlanCTA: true,
          paymentCTA: true,
          billingHistoryCTA: false,
        },
      };
    }
    case SubscriptionState.LIFETIME:
    case SubscriptionState.MEMBER: {
      return {
        title: LOKALISED_ACTIVE_STATUS,
        content: LOKALISED_B2B_MEMBERSHIP,
        color: ACTIVE_COLOR,
        show: {
          paymentDetails: false,
          membershipPlanCTA: true,
          paymentCTA: false,
          billingHistoryCTA: false,
        },
      };
    }
    default: {
      return isStrictNever(subscriptionState);
    }
  }
};

const handleActiveB2C = (
  paymentMethodStatus: PaymentMethodState,
  nextPaymentDate: string,
  isValidCurrency: boolean,
  billingPeriod?: BillingPeriod,
): BillingInformation => {
  const nextPaymentDateToLocale = new Date(nextPaymentDate).toLocaleDateString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const LOKALISED_NEXT_PAYMENT = t('membership_hub_billing_status_active_description_next_payment_on', {
    date: nextPaymentDateToLocale,
  });

  switch (paymentMethodStatus) {
    case PaymentMethodState.ACTIVE: {
      const title = billingPeriod ? (
        <>
          {LOKALISED_ACTIVE_STATUS}&nbsp;(
          {t(periodToProductPlanNameKey(billingPeriod))})
        </>
      ) : (
        LOKALISED_ACTIVE_STATUS
      );
      return {
        title,
        content: LOKALISED_NEXT_PAYMENT,
        color: ACTIVE_COLOR,
        show: {
          paymentDetails: true,
          membershipPlanCTA: isValidCurrency,
          paymentCTA: isValidCurrency,
          billingHistoryCTA: true,
        },
      };
    }
    case PaymentMethodState.NO_PAYMENT: {
      return {
        title: t('membership_hub_billing_status_payment_required'),
        content: t('membership_hub_payment_required_description', {
          date: nextPaymentDateToLocale,
        }),
        color: INACTIVE_COLOR,
        show: {
          paymentDetails: false,
          membershipPlanCTA: isValidCurrency,
          paymentCTA: isValidCurrency,
          billingHistoryCTA: true,
        },
      };
    }
    case PaymentMethodState.EXPIRED: {
      return {
        title: t('membership_hub_billing_status_payment_required'),
        content: t('membership_hub_expired_payment_method', {
          date: nextPaymentDateToLocale,
        }),
        color: INACTIVE_COLOR,
        show: {
          paymentDetails: true,
          membershipPlanCTA: isValidCurrency,
          paymentCTA: isValidCurrency,
          billingHistoryCTA: true,
        },
      };
    }
  }
};

export const getBillingInformation = ({
  paymentMethodStatus,
  nextPaymentDate,
  pendingCancellation,
  membershipType,
  subscriptionState,
  dunningLevel,
  endDate,
  billingPeriod,
  pendingPrepaidMonths,
  isValidCurrency,
}: getBillingInformationProps): BillingInformation => {
  const isB2B = isB2BMember(membershipType);
  const isPrepaidPending = pendingPrepaidMonths > 0;
  if (isB2B) {
    return handleB2BStatus(subscriptionState as B2BSubscriptionState, isPrepaidPending);
  }

  const paymentMethodExists = paymentMethodStatus !== PaymentMethodState.NO_PAYMENT;

  const isDunning = dunningLevel && dunningLevel > 0;
  if (isDunning) {
    return {
      title: t('membership_hub_billing_status_payment_failed'),
      content: t('membership_hub_billing_status_payment_failed_description_dunning'),
      color: INACTIVE_COLOR,
      show: {
        paymentDetails: paymentMethodExists,
        membershipPlanCTA: true,
        paymentCTA: true,
        billingHistoryCTA: true,
      },
    };
  }

  if (pendingCancellation) {
    if (!endDate) {
      // Should never happen
      throwError('hubGeneralError', 'No end date found for pending cancellation subscription');
    }

    const subscriptionEndDate = new Date(endDate).toLocaleDateString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return {
      title: t('membership_hub_billing_status_pending_cancellation'),
      content: t('membership_hub_billing_status_pending_cancellation_description', { date: subscriptionEndDate }),
      color: INACTIVE_COLOR,
      show: {
        paymentDetails: paymentMethodExists,
        membershipPlanCTA: isValidCurrency, // no valid currency, we let the user subsciption to be expired then they can renew with a new matching currency & country address and pm
        paymentCTA: isValidCurrency,
        billingHistoryCTA: true,
      },
    };
  }

  switch (subscriptionState) {
    // Inactive states
    case SubscriptionState.PENDING:
    case DROPOUT_OR_CORRUPTED_ACCOUNT: {
      return {
        title: LOKALISED_PENDING_STATUS,
        content: t('membership_hub_finish_onboarding_flow'),
        color: INACTIVE_COLOR,
        show: {
          paymentDetails: false,
          membershipPlanCTA: false,
          paymentCTA: false,
          billingHistoryCTA: false,
        },
      };
    }
    // for b2c member whose subscription is expired
    case SubscriptionState.EXPIRED: {
      return {
        title: isPrepaidPending ? LOKALISED_PREPAID_PENDING_STATUS : LOKALISED_INACTIVE_STATUS,
        content: isPrepaidPending ? LOKALISED_PREPAID_PENDING_MEMBERSHIP : LOKALISED_INACTIVE_MEMBERSHIP,
        color: INACTIVE_COLOR,
        show: {
          paymentDetails: paymentMethodExists,
          membershipPlanCTA: true,
          paymentCTA: true,
          billingHistoryCTA: true,
        },
      };
    }
    // Active states
    case SubscriptionState.LIFETIME: {
      return {
        title: LOKALISED_ACTIVE_STATUS,
        content: t('membership_hub_billing_status_active_description_lifetime_membership'),
        color: ACTIVE_COLOR,
        show: {
          paymentDetails: false,
          membershipPlanCTA: true,
          paymentCTA: false,
          billingHistoryCTA: false,
        },
      };
    }
    case SubscriptionState.TRIAL: {
      if (pendingPrepaidMonths > 0) {
        return {
          title: LOKALISED_ACTIVE_STATUS,
          content: (
            <>
              {t('membership_hub_x_month_trial')}
              {' - '}
              {LOKALISED_PREPAID_PENDING_STATUS}
            </>
          ),
          color: ACTIVE_COLOR,
          show: {
            paymentDetails: false,
            membershipPlanCTA: isValidCurrency,
            paymentCTA: isValidCurrency,
            billingHistoryCTA: false,
          },
        };
      }
      if (!nextPaymentDate) {
        // Should never happen
        throwError('hubGeneralError', 'Billing period or next billing date missing for active member');
      }
      return handleActiveB2C(paymentMethodStatus, nextPaymentDate, isValidCurrency, billingPeriod);
    }
    case SubscriptionState.MEMBER: {
      if (!nextPaymentDate) {
        // Should never happen
        throwError('hubGeneralError', 'Billing period or next billing date missing for active member');
      }
      return handleActiveB2C(paymentMethodStatus, nextPaymentDate, isValidCurrency, billingPeriod);
    }

    default: {
      return isStrictNever(subscriptionState);
    }
  }
};
