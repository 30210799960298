import { useState } from 'react';

import { useMutation } from '@tanstack/react-query';
import { emailRegex } from 'common-utils';
import { LoginPasswordRequestBody, LoginResponse } from 'oura-account-api-client';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { styled } from 'styled-components';
import { t } from 'translations';

import { useAuth } from 'apps-common/context/auth';
import { loginUser } from 'apps-common/hooks/AccountApi';
import { setUserId, track } from 'apps-common/utils/analytics';
import { logger } from 'apps-common/utils/logger';
import { Button, CTALoader, EmailField, ErrorBanner, Form, PasswordField, SubmitButton } from 'ui';
import { Link } from 'ui/styles/text';
import { media } from 'ui/themes/signup';

import { routes } from '../routes';
import { useStore } from '../store';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > form {
    margin-top: 10px;
  }

  & > form > ${Button}[type="submit"] {
    margin-top: 10px;
  }

  ${media.xlarge`
        margin-top: 20px;
        width: 50%;
        align-self: flex-start;

        & > form {
            max-width: 100%;
        }

        & > form > ${Button}[type="submit"] {
            align-self: flex-start;
        }
  `};
`;

type FormValues = LoginPasswordRequestBody & {
  serverError?: string;
};

export const LoginForm = () => {
  const { login } = useAuth();
  const { clearErrors, formState, handleSubmit, register, setError } = useForm<FormValues>({
    criteriaMode: 'all',
    mode: 'onTouched',
  });

  const intl = useIntl();
  const loginMutation = useMutation({
    mutationFn: loginUser,
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const nextPath = searchParams.get('next') ?? '';
  const setAccountId = useStore((state) => state.setAccountId);

  const onSubmit = (data: LoginPasswordRequestBody) => {
    loginMutation.mutate(data, {
      onSuccess: (res: LoginResponse) => {
        logger.info('Successful log in');
        track({
          event: 'CTA Clicked',
          payload: {
            cta: 'login',
            action: 'login',
          },
        });
        setAccountId(res.accountId);
        setUserId(res.analyticsId);
        login();
        navigate(nextPath ? nextPath : routes.index);
      },
      onError: (error) => {
        const { message, name } = error;
        logger.warn('Login failed', { message, name });
        track({
          event: 'Login Failed',
          payload: { reason: message },
        });
        setError('serverError', {
          type: 'server',
          message: message ?? t('membership_hub_login_failed_error_message'),
        });
      },
    });
  };

  const [showPassword, setShowPassword] = useState(false);
  const { isPending } = loginMutation;

  return (
    <Container>
      {formState.errors.serverError && <ErrorBanner>{formState.errors.serverError.message}</ErrorBanner>}
      <Form
        onSubmit={handleSubmit(onSubmit)}
        onFocus={() => {
          clearErrors('serverError');
        }}
      >
        <EmailField
          placeholder={intl.formatMessage({ id: 'membership_hub_enter_email' })}
          {...register('email', {
            required: {
              value: true,
              message: intl.formatMessage({
                id: 'membership_hub_email_required_error_message',
              }),
            },
            pattern: {
              value: emailRegex,
              message: intl.formatMessage({
                id: 'membership_error_invalid_email',
              }),
            },
          })}
          error={formState.errors.email?.message}
        />
        <PasswordField
          showPassword={showPassword}
          onRevealIconClick={() => {
            setShowPassword(!showPassword);
          }}
          placeholder={intl.formatMessage({
            id: 'membership_hub_enter_password',
          })}
          {...register('password', {
            required: {
              value: true,
              message: intl.formatMessage({
                id: 'membership_hub_password_required_error_message',
              }),
            },
          })}
          error={formState.errors.password?.message}
        />

        <Link
          $color="helsinkiBlue"
          $fontSize="12px"
          $fontWeight="700"
          $textAlign="right"
          href={routes.requestPasswordReset}
        >
          {intl.formatMessage({ id: 'membership_hub_forgot_password' })}
        </Link>
        <SubmitButton disabled={!formState.isValid || isPending}>
          {isPending ? <CTALoader /> : intl.formatMessage({ id: 'membership_hub_login_button' })}
        </SubmitButton>
      </Form>
    </Container>
  );
};
