import { useAuth } from 'apps-common/context/auth';
import { Flags, useFlag } from 'apps-common/utils/featureFlags';
import { Header } from 'ui';
import { MainContainer } from 'ui/styles/containers';

import { LoginForm } from '../components/LoginForm';
import MoiLogin from '../components/MoiLogin';

export const LoginPage = () => {
  const { login } = useAuth();
  const moiFlag = useFlag(Flags.MOI_AUTH);
  return (
    <>
      {moiFlag ? (
        <MoiLogin onLogin={login} />
      ) : (
        <>
          <Header pageType="landing" appType="hub" />
          <MainContainer>
            <LoginForm />
          </MainContainer>
        </>
      )}
    </>
  );
};
