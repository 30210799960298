import { useEffect } from 'react';

import { styled } from 'styled-components';

import { Paragraph } from 'ui/styles/text';

import { RenderElementInputs, initHSA } from '../../utils/hsa';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 40px;
`;

export interface HSAFormProps {
  id: string;
  accountId: string;
  email: string;
  merchantId: string;
  onCancel: RenderElementInputs['onCancel'];
  onError: RenderElementInputs['onError'];
  onSubmit: React.MouseEventHandler<HTMLButtonElement>;
  onSuccess: RenderElementInputs['onSave'];
}

export const HSAForm = ({
  accountId,
  email,
  id,
  merchantId,
  onCancel,
  onError,
  // onSubmit, // not supported by Sika yet
  onSuccess,
}: HSAFormProps) => {
  useEffect(() => {
    initHSA({
      accountId,
      email,
      componentId: id,
      merchantId,
      onCancel,
      onError,
      onSuccess,
    });
  }, [accountId, id, email, merchantId, onCancel, onSuccess, onError]);

  return (
    <Container>
      <Paragraph $fontSize="medium" $lineHeight="140%">
        Click the button below to be redirected to HSA/FSA to securely authorize your purchase.
      </Paragraph>
      <div id={id} />
    </Container>
  );
};
