import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { t } from 'translations/src/LocaleContext';

import { useGetAccount } from 'apps-common/hooks/useGetAccount';
import { throwError } from 'apps-common/utils/errorHandler';
import { Header, Loader } from 'ui';
import { MainContainer } from 'ui/styles/containers';

import { NavigationRow, PreviewRow, SectionCard } from '../../components/SectionCard';
import { routes } from '../../routes';
import { isB2BMember, isLifetimeMember } from '../../utils/member';

import { getCTAInfo, getCurrentPlan, getUpcomingPlans } from './getMembershipPlans';

export const MembershipPlanPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    data: accountData,
    error: errorInGetAccount,
    isError: isErrorInGetAccount,
    isFetching: isFetchingAccountData,
  } = useGetAccount();

  if (isFetchingAccountData) {
    return <Loader />;
  }

  const title = t('membership_hub_product_page_title');
  const currentPlanTitle = t('membership_hub_current_plan_title');
  const upcomingPlanTitle = t('membership_hub_upcoming_plan_title');

  if (isErrorInGetAccount || !accountData?.account || !accountData.membershipOffering) {
    throwError('errorOnGetAccount', errorInGetAccount);
  }
  const {
    account: { membershipType },
  } = accountData;
  const isB2BOrLifeTimeUser = isB2BMember(membershipType) || isLifetimeMember(membershipType);
  const currentPlan = getCurrentPlan(accountData);
  const upcomingPlans = getUpcomingPlans(accountData.account);
  const hasUpcomingPlans = !isEmpty(upcomingPlans) || !!accountData.account?.currentSubscription?.pendingCancellation;
  const ctaInfo = getCTAInfo(accountData.account);

  const changePlanNav = ctaInfo && (
    <NavigationRow
      label={intl.formatMessage({ id: ctaInfo.cta.label })}
      href={ctaInfo.cta.href}
      color={ctaInfo.cta.color}
    />
  );

  return (
    <>
      <Header appType="hub" pageType="create" title={title} onBackClick={() => navigate(routes.index)} />
      <MainContainer $padding="10px 0" $maxWidth="794px">
        {currentPlan && (
          <SectionCard title={currentPlanTitle}>
            <PreviewRow
              label={currentPlan?.label}
              rowValues={currentPlan?.planDetails ?? []}
              isLast={hasUpcomingPlans || isB2BOrLifeTimeUser}
            />
            {!hasUpcomingPlans && changePlanNav}
          </SectionCard>
        )}

        {hasUpcomingPlans && (
          <SectionCard title={upcomingPlanTitle}>
            {upcomingPlans.map((plan, idx) => (
              <PreviewRow key={idx} label={plan.label} rowValues={plan.planDetails} />
            ))}
            {changePlanNav}
          </SectionCard>
        )}
      </MainContainer>
    </>
  );
};
