import { PropsWithChildren, forwardRef } from 'react';

import { styled } from 'styled-components';

// Src: https://moderncss.dev/pure-css-custom-styled-radio-buttons/
const RadioInput = styled.input(
  ({ theme }) => `
  appearance: none;
  cursor: pointer;
  margin: 0;
  font-size: 24px;
  width: 1em;
  height: 1em;
  border: 0.1em solid ${theme.colors.white2};
  border-radius: 50%;

  display: grid;
  place-content: center;

  &:focus {
    outline: max(2px, 0.1em) solid ${theme.colors.grayMedium};
    outline-offset: max(2px, 0.05em);
  }

  &::before {
    content: "";
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    background-color: ${theme.colors.helsinkiBlue};
    /* transform for chekced state, with animations */
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }

  &:checked::before {
    transform: scale(1);
  }
`,
);

export type RadioButtonProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'>;

export const RadioButton = forwardRef<HTMLInputElement, PropsWithChildren<RadioButtonProps>>((props, ref) => {
  return <RadioInput type="radio" ref={ref} {...props} />;
});
