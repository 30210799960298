import { ApiProduct, BillingPeriod, Product, RatePlan, Subscription } from '../types';

import { throwError } from './errorHandler';

const getProductsForCountry = (products: ApiProduct[], country: string | undefined): Product[] => {
  if (!country) return [];
  return products
    .filter((p) => p.countries.map((c) => c.code).includes(country))
    .map((ap) => apiProductToProduct(ap, country));
};

const apiProductToProduct = (ap: ApiProduct, country: string): Product => {
  const { name, description, channel, membership, taxMode, trial } = ap;
  const countryData = ap.countries.find((p) => p.code === country);

  // Country (and thus pricing data) always exists here
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const pricing = countryData?.pricing!;

  return {
    name,
    description,
    channel,
    membership,
    taxMode,
    trial,
    pricing,
  };
};

const sortByBillingPeriod = (a: RatePlan, b: RatePlan, defaultSortOrder = true) =>
  defaultSortOrder ? a.billingPeriod.localeCompare(b.billingPeriod) : b.billingPeriod.localeCompare(a.billingPeriod);

export const getProduct = (products: ApiProduct[], country: string, type: BillingPeriod): Product | undefined => {
  const product = getProductsForCountry(products, country).find((p) => p.membership.periodType === type);

  return product;
};

export const convertProductToRatePlan = (product: Product): RatePlan => ({
  amount: product.pricing.amount,
  billingPeriod: product.membership.periodType,
  currency: product.pricing.currency,
});

export const getRatePlansForCountry = (
  apiProducts: ApiProduct[],
  country: string | undefined,
  defaultSortOrder = true, // monthly rate plan placed first (pre-selected)
): RatePlan[] => {
  const products = getProductsForCountry(apiProducts, country);
  return products.map(convertProductToRatePlan).sort((a, b) => sortByBillingPeriod(a, b, defaultSortOrder));
};

export const ratePlansFromSubscription = (currentSubscription: Subscription) => {
  const { recurringFee: currentPlan, alternativeBillingPeriods: altPlans } = currentSubscription;

  if (!currentPlan || !altPlans) {
    throwError('hubGeneralError', 'No rate plans found from current subscription');
  }

  const ratePlans = [currentPlan, ...altPlans].toSorted(sortByBillingPeriod);
  return ratePlans;
};

export const getRatePlan = (products: ApiProduct[], country: string, type: BillingPeriod): RatePlan | undefined => {
  const product = getProduct(products, country, type);
  return product && convertProductToRatePlan(product);
};
