import { getYear } from 'date-fns';
import groupBy from 'lodash/groupBy';
import { LocaleSupported } from 'translations';

import { preciseSum } from '../utils/precisionArithmetic';

export enum Channel {
  eCommerce = 'eCommerce',
  retail = 'retail',
  b2b = 'b2b',
  testing = 'testing',
  Unknown = 'Unknown',
}

export interface PaymentSignature {
  key: string;
  signature: string;
  tenantId: string;
  token: string;
}

export enum PaymentMethodType {
  CreditCard = 'CreditCard',
  PayPal = 'PayPal',
  HSA_FSA = 'HSA/FSA',
}

export interface PaymentGateways {
  hostedPageId: string;
  creditCardGatewayName: string;
  paypalGatewayName: string;
  sikaHealthGatewayName: string;
}
// TODO: cannot use this, since capitalization leaves PayPal to payPalGateway, while we want paypalGateway
// & Record<Uncapitalize<`${PaymentMethodType}GatewayName`>, string>;

export enum CardType {
  Visa = 'Visa',
  MasterCard = 'MasterCard',
  AmericanExpress = 'AmericanExpress',
  Discover = 'Discover',
  Diners = 'Diners',
  JCB = 'JCB',
}

export enum FlowType {
  renew = 'renew',
  normal = 'normal',
}

export type PrepopulateFields = Partial<{
  creditCardCountry: string;
  creditCardState: string;
  creditCardAddress1: string;
  creditCardAddress2: string;
  creditCardCity: string;
  creditCardPostalCode: string;
}>;

export type HostedPageParams = PaymentSignature & {
  id: string;
  style: 'inline';
  submitEnabled: false;
  locale: LocaleSupported;
  url:
    | 'https://sandbox.eu.zuora.com/apps/PublicHostedPageLite.do'
    | 'https://eu.zuora.com/apps/PublicHostedPageLite.do';
  paymentGateway: string;
  field_passthrough1: FlowType;
};

export enum EntitlementType {
  LIFETIME = 'LIFETIME',
  FIXED_12_MONTH = 'FIXED_12_MONTH',
  FIXED_24_MONTH = 'FIXED_24_MONTH',
  FIXED_36_MONTH = 'FIXED_36_MONTH',
}

export interface Entitlement {
  type: EntitlementType;
}

export enum MembershipType {
  B2B = 'B2B',
  B2C = 'B2C',
  LEGACY_HW = 'LEGACY_HW',
  LIFETIME = 'LIFETIME',
  UNFINISHED = 'UNFINISHED', // not completed signup flow
  UNVERIFIED = 'UNVERIFIED', // not email_verified
}

export enum SignupMembershipType {
  ENTITLEMENT = 'ENTITLEMENT',
  LEGACY = 'LEGACY',
  PAYING = 'PAYING',
}

type PaymentMethodStatus = 'Active' | 'Closed' | 'Scrubbed';

export interface CreditCardPaymentMethod {
  type: PaymentMethodType.CreditCard;
  status: PaymentMethodStatus;
  cardNumber: string;
  isDefault: boolean;
  isExpired: boolean;
  isReusableInRenew: boolean;
  cardType: CardType;
}

interface PayPalPaymentMethod {
  type: PaymentMethodType.PayPal;
  id: string;
  status: PaymentMethodStatus;
  isDefault: boolean;
  isReusableInRenew: boolean;
}

export type PaymentMethod = PayPalPaymentMethod | CreditCardPaymentMethod;

export type SubscriptionType = 'CHARGEABLE' | 'LIFETIME_FREE';
export type RenewalState = 'PAYMENT_METHOD_REQUIRED' | 'NO_PAYMENT_METHOD_REQUIRED' | 'RENEWAL_FORBIDDEN';

interface RecurringFee {
  amount: number;
  currency: string;
  billingPeriod: BillingPeriod;
}

export enum SubscriptionState {
  TRIAL = 'TRIAL',
  MEMBER = 'MEMBER',
  LIFETIME = 'LIFETIME',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
}

export interface PrepaidPeriod {
  start: string;
  end: string;
  lengthInMonths: number;
}
interface PrepaidPeriods {
  current: PrepaidPeriod | null;
  future: PrepaidPeriod | null;
}

export interface Subscription {
  id: string;
  nextBillingDate?: string; // TODO correct the typing here to null (MMBER-5645)
  nextPaymentDate?: string;
  endDate?: string;
  startDate?: string;
  pendingCancellation: boolean;
  recurringFee: RecurringFee | null;
  alternativeBillingPeriods: RecurringFee[] | null;
  channel: Channel;
  subscriptionType: SubscriptionType;
  renewalState: RenewalState;
  subscriptionState: SubscriptionState;
  prepaidPeriods?: PrepaidPeriods | null; // shown only for currentSubscription
}

export interface MembershipStatus {
  type: SignupMembershipType;
  subscriptionFound: boolean;
  signupComplete: boolean;
}

export interface ApiProduct {
  name: string;
  description: string | null;
  channel: Channel;
  trial: Period | null;
  membership: Period;
  taxMode: TaxMode;
  countries: Country[];
}

export interface Product {
  name: string;
  description: string | null;
  channel: Channel;
  trial: Period | null;
  membership: Period;
  taxMode: TaxMode;
  pricing: Pricing;
}

export type RatePlan = RecurringFee;

export interface Country {
  code: string;
  pricing: Pricing;
}

export interface Pricing {
  currency: string;
  amount: number;
}

export interface Period {
  length: number;
  periodType: BillingPeriod;
}

export enum BillingPeriod {
  Days = 'Days',
  Weeks = 'Weeks',
  Months = 'Months',
  Years = 'Years',
}

export enum TaxMode {
  TaxInclusive = 'TaxInclusive',
  TaxExclusive = 'TaxExclusive',
  NoTax = 'NoTax',
}

export interface Contact {
  firstName: string;
  lastName: string;
  address1: string;
  address2?: string;
  city: string;
  state?: string;
  postalCode: string;
  country: string;
  telephone?: string;
  personalEmail: string;
  addressValidated: boolean;
}

export type AddressValidationContact = Omit<Contact, 'addressValidated'>;

export interface BillingAddress {
  address1: string;
  city: string;
  country: string;
  postalCode: string;
  state?: string;
}

export interface Address {
  firstName: string;
  lastName: string;
  address1: string;
  address2?: string;
  city: string;
  state?: string;
  country: string;
  postalCode: string;
  isoAlpha3Code?: string;
}

// country list for address page
export interface Region {
  label: string;
  value: string;
}

export type Currency = 'AUD' | 'CAD' | 'EUR' | 'GBP' | 'JPY' | 'USD';

export interface CountryWeSellTo {
  countryCode: string;
  name: string;
  regions: Region[];
  isoAlpha3Code: string;
}

export type AppType = 'signup' | 'hub';

export interface MembershipOfferings {
  channel: Channel;
  entitlement: Entitlement;
  prepaidMonths: number;
  customerId: string;
  customerName: string;
  membershipStatus: MembershipStatus;
  products: ApiProduct[];
  eligibleForPartnership: boolean;
}

export enum AccessUserTypes {
  // b2c types
  ACTIVE_B2C_ALL = 'ACTIVE_B2C_ALL', // selects all active b2c types
  ACTIVE_B2C_ADDRESS_PAYMENT_METHOD = 'ACTIVE_B2C_ADDRESS_PAYMENT_METHOD',
  ACTIVE_B2C_NO_ADDRESS_PAYMENT_METHOD = 'ACTIVE_B2C_NO_ADDRESS_PAYMENT_METHOD',
  ACTIVE_B2C_ADDRESS_NO_PAYMENT_METHOD = 'ACTIVE_B2C_ADDRESS_NO_PAYMENT_METHOD',
  ACTIVE_B2C_NO_ADDRESS_NO_PAYMENT_METHOD = 'ACTIVE_B2C_NO_ADDRESS_NO_PAYMENT_METHOD',

  INACTIVE_B2C_ALL = 'INACTIVE_B2C_ALL', // selects all inactive b2c types
  INACTIVE_B2C_ADDRESS_PAYMENT_METHOD = 'INACTIVE_B2C_ADDRESS_PAYMENT_METHOD',
  INACTIVE_B2C_NO_ADDRESS = 'INACTIVE_B2C_NO_ADDRESS',
  INACTIVE_B2C_ADDRESS_NO_PAYMENT_METHOD = 'INACTIVE_B2C_ADDRESS_NO_PAYMENT_METHOD',
  INACTIVE_B2C_NO_ADDRESS_NO_PAYMENT_METHOD = 'INACTIVE_B2C_NO_ADDRESS_NO_PAYMENT_METHOD',

  PENDING_B2C = 'PENDING_B2C',
  INVALID_CURRENCY_ACTIVE_B2C = 'INVALID_CURRENCY_ACTIVE_B2C',

  // entitlement (b2b/lifetime) types
  ACTIVE_B2B = 'ACTIVE_B2B',
  INACTIVE_B2B = 'INACTIVE_B2B',
  LIFETIME = 'LIFETIME',

  // gen 2 types
  LEGACY = 'LEGACY',

  // no access to hub except pw reset page
  UNFINISHED = 'UNFINISHED', // not completed signup flow
  UNVERIFIED = 'UNVERIFIED', // not email_verified
}

export enum PaymentMethodState {
  ACTIVE = 'Active',
  NO_PAYMENT = 'No Payment Method',
  EXPIRED = 'Expired',
}

enum CompensationType {
  COMPENSATION_MONTH = 'COMPENSATION_MONTH',
  DUNNING_CANCELLATION = 'DUNNING_CANCELLATION',
  OTHER = 'OTHER',
  REFUND = 'REFUND',
}

export interface Invoice {
  id: string;
  amount: number;
  balance: number;
  date: string; // ISO date string
  compensationType: CompensationType | null;
  compensationAmount: number;
}

export interface AnnualReport {
  id: string;
  amount: number;
  year: number;
}

export const invoicesToAnnualReport = (invoices: Invoice[]): AnnualReport[] => {
  return Object.entries(groupBy(invoices, (inv) => getYear(inv.date))).map(([year, invoices]) => {
    const annualReport: AnnualReport = {
      id: year,
      year: Number.parseInt(year, 10),
      amount: preciseSum(...invoices.map((i) => i.amount)),
    };
    return annualReport;
  });
};

type Field = 'address1' | 'address2' | 'postalCode' | 'city' | 'state' | 'country';

export interface Problem {
  field: Field | null;
  message: string;
  correctedValue: string;
}
