import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

const isLocal = import.meta.env.DEV;

const DSN: string = import.meta.env.VITE_SENTRY_DSN;
const TRACES_SAMPLE_RATE: number = import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE;
const REPLAYS_SAMPLE_RATE: number = import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE;

export function initSentry(depth = 3) {
  Sentry.init({
    enabled: !isLocal,
    environment: import.meta.env.MODE,
    dsn: DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.httpClientIntegration(),
      Sentry.extraErrorDataIntegration({ depth }),
      Sentry.replayIntegration(),
    ],
    normalizeDepth: depth + 1,
    // Performance Monitoring
    tracesSampleRate: !isLocal ? TRACES_SAMPLE_RATE : 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: !isLocal ? REPLAYS_SAMPLE_RATE : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
