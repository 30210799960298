import { ReactNode, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';

import { GetAccountResponse } from 'apps-common/graphql/getAccount';
import { useGetAccount } from 'apps-common/hooks/useGetAccount';
import { useGetSellToCountries } from 'apps-common/hooks/useSellToCountries';
import { AccessUserTypes, MembershipType } from 'apps-common/types';
import { throwError } from 'apps-common/utils/errorHandler';
import { Flags, useFlag } from 'apps-common/utils/featureFlags';
import { isStrictNever } from 'apps-common/utils/isStrictNever';
import { logger } from 'apps-common/utils/logger';
import { Loader } from 'ui';

import { routes } from '../routes';
import { canUseExistingAddressForPaymentMethod } from '../utils/address';
import { isActiveSubscription, isLegacyMember, isPendingSubscription } from '../utils/member';

// discriminated union types to always give either one of user list (allowedUsers | excludedUsers) but never give both or none
export type UserAccessProps =
  | { allowedUsers: AccessUserTypes[]; excludedUsers?: never }
  | { excludedUsers: AccessUserTypes[]; allowedUsers?: never };

export interface UserAccessCheckProps {
  userAccessRights: UserAccessProps;
  children: ReactNode;
}

const getUserType = (
  membershipType: MembershipType,
  currentSubscription: GetAccountResponse['account']['currentSubscription'] | undefined,
  paymentMethods: GetAccountResponse['account']['paymentMethods'] | undefined,
  isValidAddress: boolean,
  isValidCurrency: boolean,
): AccessUserTypes => {
  if (isLegacyMember(membershipType)) {
    return AccessUserTypes.LEGACY;
  }

  if (!currentSubscription) {
    return AccessUserTypes.UNFINISHED;
  }

  const subscriptionState = currentSubscription.subscriptionState;

  switch (membershipType) {
    case MembershipType.LIFETIME: {
      return AccessUserTypes.LIFETIME;
    }

    case MembershipType.UNFINISHED: {
      return AccessUserTypes.UNFINISHED;
    }

    case MembershipType.UNVERIFIED: {
      return AccessUserTypes.UNVERIFIED;
    }

    case MembershipType.B2C: {
      if (isPendingSubscription(subscriptionState)) {
        return AccessUserTypes.PENDING_B2C;
      }

      // Active subscription
      if (isActiveSubscription(subscriptionState) && !currentSubscription.pendingCancellation) {
        if (!isValidCurrency) {
          return AccessUserTypes.INVALID_CURRENCY_ACTIVE_B2C;
        }
        if (!isEmpty(paymentMethods)) {
          return isValidAddress
            ? AccessUserTypes.ACTIVE_B2C_ADDRESS_PAYMENT_METHOD
            : AccessUserTypes.ACTIVE_B2C_NO_ADDRESS_PAYMENT_METHOD;
        }
        return isValidAddress
          ? AccessUserTypes.ACTIVE_B2C_ADDRESS_NO_PAYMENT_METHOD
          : AccessUserTypes.ACTIVE_B2C_NO_ADDRESS_NO_PAYMENT_METHOD;
      }

      if (currentSubscription.pendingCancellation && !isValidCurrency) {
        return AccessUserTypes.INVALID_CURRENCY_ACTIVE_B2C;
      }

      // Expired or pending cancellation subscription
      if (!isEmpty(paymentMethods)) {
        return isValidAddress
          ? AccessUserTypes.INACTIVE_B2C_ADDRESS_PAYMENT_METHOD
          : AccessUserTypes.INACTIVE_B2C_NO_ADDRESS;
      }
      return isValidAddress
        ? AccessUserTypes.INACTIVE_B2C_ADDRESS_NO_PAYMENT_METHOD
        : AccessUserTypes.INACTIVE_B2C_NO_ADDRESS_NO_PAYMENT_METHOD;
    }

    case MembershipType.B2B: {
      if (isActiveSubscription(subscriptionState)) {
        return AccessUserTypes.ACTIVE_B2B;
      }
      return AccessUserTypes.INACTIVE_B2B;
    }

    default: {
      return isStrictNever(membershipType);
    }
  }
};

const isAllowedUser = (
  membershipType: MembershipType,
  userAccessRights: UserAccessProps,
  currentSubscription: GetAccountResponse['account']['currentSubscription'] | undefined,
  paymentMethods: GetAccountResponse['account']['paymentMethods'] | undefined,
  isValidAddress: boolean,
  isValidCurrency: boolean,
) => {
  const userType = getUserType(membershipType, currentSubscription, paymentMethods, isValidAddress, isValidCurrency);

  if ('excludedUsers' in userAccessRights) {
    return !userAccessRights.excludedUsers!.some((allowedType: AccessUserTypes) =>
      allowedType.endsWith('_ALL') ? userType.startsWith(allowedType.slice(0, -4)) : userType === allowedType,
    );
  }

  return userAccessRights.allowedUsers.some((allowedType) =>
    allowedType.endsWith('_ALL') ? userType.startsWith(allowedType.slice(0, -4)) : userType === allowedType,
  );
};

const useRedirectToHomePageForNonAccessUsers = (
  isAllowedUser: boolean | undefined | null, // undefined or null means still calculating the userType.
) => {
  const navigate = useNavigate();
  useEffect(() => {
    // only when explicitly false, redirect to home page. Otherwise, do nothing.
    if (isAllowedUser == false) {
      navigate(routes.index);
    }
  }, [isAllowedUser, navigate]);
};

const useUnfinishedOrUnverifiedUserCheck = () => {
  const { data: accountData } = useGetAccount();
  const membershipType = accountData?.account.membershipType;

  useEffect(() => {
    if (MembershipType.UNFINISHED === membershipType) {
      // The customer is not a legacy hardware user but they don't have subscription either (not even expired one).
      // They should be asked to finish the signup flow.
      throwError('errorOnB2CNoSubNoAccessOrNullMembershipType');
    } else if (MembershipType.UNVERIFIED === membershipType) {
      // The customer's account is unverified. They should be asked to verify it by requesting a new password
      // through forgot password feature.
      throwError('errorOnUnverifiedAccount');
    }
  }, [membershipType]);
};

export const UserAccessCheck = ({ userAccessRights, children }: UserAccessCheckProps) => {
  const { data: accountData, isFetching, isError, error } = useGetAccount();
  const {
    data: countryData,
    isError: isErrorGetCountries,
    isFetching: isFetchingCountries,
    error: errorInGetCountries,
  } = useGetSellToCountries(!!accountData?.account);
  const moiFlag = useFlag(Flags.MOI_AUTH);

  const { currentSubscription, membershipType, paymentMethods, billToContact, shipToContact, isValidCurrency } =
    accountData?.account ?? {};

  const isValidAddress = canUseExistingAddressForPaymentMethod(
    shipToContact ?? null,
    billToContact ?? null,
    countryData,
  );

  const isUserAllowed =
    membershipType &&
    isAllowedUser(
      membershipType,
      userAccessRights,
      currentSubscription,
      paymentMethods,
      isValidAddress,
      isValidCurrency ?? true,
    );

  useUnfinishedOrUnverifiedUserCheck();
  useRedirectToHomePageForNonAccessUsers(isUserAllowed);

  if (isFetching || isFetchingCountries) {
    return <Loader />;
  }

  if (isError) {
    if (moiFlag) logger.error('Error getting account', error);
    else throwError('errorOnGetAccount', error);
    return null;
  }

  if (isErrorGetCountries) {
    if (moiFlag) logger.error('Error getting countries', errorInGetCountries);
    else throwError('errorOnGettingCountries', errorInGetCountries);
    return null;
  }

  if (!membershipType) {
    // Account not found. The customer should create the account and membership through the signup flow.
    if (moiFlag) logger.error('Membership type not found in account data.');
    else throwError('errorOnB2CNoSubNoAccessOrNullMembershipType');
    return null;
  }

  if (!isUserAllowed) {
    // Return null to prevent flash of content before redirect
    return null;
  }
  return children;
};
